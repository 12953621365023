html {
  overflow: hidden;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-box {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 10vh;
  padding-bottom: 10vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    z-index: 1;
    height: 30vh;
    position: relative;
    width: auto;
    border-radius: 50%;
    border: 1px solid seashell;
  }
}
